import { Component } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'tr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'touch-reports';
  languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn'];
  language$!:  Observable<any>;

  onLoginClick() {
    // this.store.dispatch(new ActionAuthLogin());
  }

  onLogoutClick() {
    // this.store.dispatch(new ActionAuthLogout());
  }

  // @ts-ignore
  onLanguageSelect({ value: language }) {
    // this.store.dispatch(new ActionSettingsChangeLanguage({ language }));
  }

}
