<footer class="footer" fxFlex fxLayout="column" fxFlexAlign="flex-center" fxLayoutGap="0.5%" *ngIf="showFooter$ | async">

    <div class="links" fxLayoutGap="15px">
      <a href="https://www.github.com/inhand" target="_blank">
        <fa-icon [icon]="['fab','github']"></fa-icon>
        <span>Github</span>
      </a>
      <a href="https://www.twitter.com/inhand" target="_blank">
        <fa-icon [icon]="['fab','twitter']"></fa-icon>
        <span>Twitter</span>
      </a>
      <a href="http://inhand.co.uk" target="_blank">
        <fa-icon [icon]="faHome"></fa-icon>
        <span>Inhand</span>
      </a>
    </div>

    <div class="signature">
      &#169; <span class="year" data-testid="footer-year">2014-2024</span> - InHand Systems Limited
      <br class="d-block d-sm-none">
      <a [matTooltip]="'Changelog'"
         matTooltipPosition="before"
         href="http://inhand.co.uk">
        <fa-icon [icon]="faRocket"></fa-icon>
        V0.1
      </a>
    </div>

</footer>
