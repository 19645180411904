import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeTRModule } from './fontawesome.module';
import { AuthService } from './authorisation/authorisation.service';
import { FooterComponent } from './navigation/footer/footer.component';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NavigationEffects } from './navigation/state/navigation.effects';
import { EffectsModule } from '@ngrx/effects';
import { NavigationService } from './navigation/navigation.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { navigationReducer } from './navigation/state/navigation.reducer';
import { TokenInterceptor } from './authorisation/token.interceptor';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { authReducer } from './authorisation/state/auth.reducer';
import { AuthEffects } from './authorisation/state/auth.effects';
import { reducers } from './state/app.state';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAlignLeft,
  faBars, faBinoculars,
  faBook,
  faCalendar,
  faCamera,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare, faClipboardCheck,
  faCog,
  faEdit,
  faExclamationTriangle, faFileInvoice, faFileSignature,
  faFilter,
  faHome, faIndustry,
  faInfoCircle,
  faLanguage,
  faLightbulb,
  faListUl, faMobileAlt,
  faPaintBrush,
  faPencilRuler,
  faPlayCircle,
  faPlus,
  faPowerOff, faQuestion,
  faRocket,
  faSlidersH,
  faSquare, faStar,
  faStream, faSyncAlt,
  faTachometerAlt,
  faTasks,
  faTimes,
  faTimesCircle,
  faToggleOn,
  faTrash,
  faUserCircle, faUserPlus,
  faWindowMaximize
} from '@fortawesome/free-solid-svg-icons';
import { faBarcode } from '@fortawesome/free-solid-svg-icons/faBarcode';
import { faGithub, faInstagram, faMediumM, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavListComponent,
    FooterComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    FontAwesomeModule,
    NgxChartsModule,
    FormsModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot([]),
    StoreModule.forFeature('nav', navigationReducer),
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([NavigationEffects, AuthEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    , connectInZone: true}),
    AppRoutingModule,

  ],
  providers: [AuthService,
    NavigationService,
    AuthEffects,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor( library: FaIconLibrary) {
    library.addIcons(
      faAlignLeft,
      faBarcode,
      faBars,
      faCalendar,
      faCamera,
      faCheckCircle,
      faCheckSquare,
      faInfoCircle,
      faUserCircle,
      faPowerOff,
      faCog,
      faListUl,
      faRocket,
      faPencilRuler,
      faPlayCircle,
      faGithub,
      faMediumM,
      faTwitter,
      faInstagram,
      faYoutube,
      faPlus,
      faEdit,
      faToggleOn,
      faTrash,
      faTimes,
      faTimesCircle,
      faCaretUp,
      faCaretDown,
      faExclamationTriangle,
      faFilter,
      faTasks,
      faCheck,
      faSquare,
      faLanguage,
      faPaintBrush,
      faLightbulb,
      faWindowMaximize,
      faSlidersH,
      faStar,
      farStar,
      faStream,
      faBook,
      faHome,
      faTachometerAlt,
      faFileSignature,
      faStream,
      faMobileAlt,
      faQuestion,
      faSyncAlt,
      faUserCircle,
      faIndustry,
      faUserPlus,
      faFileInvoice,
      faClipboardCheck,
      faBinoculars,
      faIndustry
    );
  }
}
