<mat-nav-list>
  <a *ngFor="let menu of sideMenu$ | async" mat-list-item routerLink="{{menu.link}}" (click)="onClose()">
    <span class="nav-caption">{{menu.title}}</span>
  </a>
  <a *ngIf="!(isAuth$ | async)" mat-list-item routerLink="/authorisation/sign-up" (click)="onClose()">
    <span class="nav-caption">Signup</span>
  </a>
  <a *ngIf="!(isAuth$ | async)" mat-list-item routerLink="/authorisation/sign-in" (click)="onClose()">
    <span class="nav-caption">Signin</span>
  </a>
  <mat-list-item *ngIf="isAuth$ | async">
    <button mat-icon-button (click)="onLogout()">
      <span class="nav-caption">Logout</span>
    </button>
  </mat-list-item>
</mat-nav-list>
