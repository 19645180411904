export interface User {
  username: string;
  email: string;
  password: string;
}

export enum roles {
  user = 0,
  lead,
  admin
}
