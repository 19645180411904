<mat-toolbar color="primary">
  <div fxHide.gt-xs>
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div fxFlex fxLayout fxLayoutAlign.xs="center center">
    <a routerLink="/"><img src="/assets/tr-logo.svg"></a>
  </div>
  <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs>
    <ul fxLayout fxLayoutGap="10px" class="navigation-items">
      <li>
        <a routerLink="" [fragment]="'faq'">Faq</a>
      </li>
      <li *ngFor="let menu of headerMenu$ | async" >
        <a routerLink="{{menu.link}}">{{menu.title}}</a>
      </li>
      <li *ngIf="!(isAuth$ | async)">
        <a routerLink="/authorisation/sign-up">Signup</a>
      </li>

      <li *ngIf="!(isAuth$ | async)">
        <a routerLink="/authorisation/sign-in">Signin</a>
      </li>
      <li *ngIf="isAuth$ | async">
        <a (click)="onLogout()">Logout</a>
      </li>
      <li *ngIf="isAuth$ | async">
        <a routerLink="/authorisation/change-password/normal">Change Password</a>
      </li>
    </ul>
  </div>
</mat-toolbar>
