import { AuthActions, AuthActionTypes } from './auth.actions';
import { CallingState, CallState } from '../../state/app.state';
// import { CognitoUserAttributes } from '../../../../../TouchAppsServerless/lambdas/cognito/CognitoUserAttributes';
import { CognitoUser } from 'amazon-cognito-identity-js';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries,@nx/enforce-module-boundaries
// import { CognitoUserAttributes } from '../../../../../touch-apps-serverless/src/lambdas/cognito/CognitoUserAttributes';

export interface AuthState {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isLead: boolean;
  isUser: boolean;
  email?: string;    // used on request new account conf or forgotten password/password reset
  resendConfirmSent?: boolean;
  requestPWSent?: boolean;
  awaitingConfirmation?: boolean;
  callState: CallState;
  userAttributes: any;      // Should be CognitoUserAttributes
  cognitoUser: CognitoUser;
}

// @ts-ignore
const initialState: AuthState = {
  isAuthenticated: false,
  isAdmin: false,
  isLead: false,
  isUser: false,
  email: '',
  resendConfirmSent: false,
  requestPWSent: false,
  awaitingConfirmation: false,
  callState: CallingState.INIT,
  // @ts-ignore
  userAttributes: null,
  // @ts-ignore
  cognitoUser: null
};

export function authReducer(state = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.SIGNUP:
      return {
        ...state,
        callState: CallingState.CALLING,
        awaitingConfirmation: false
      }
    case AuthActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
        awaitingConfirmation: true
      }
    case AuthActionTypes.SIGNUP_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED,
        awaitingConfirmation: false
      }
    case AuthActionTypes.SIGNIN:
      return {
        ...state,
        callState: CallingState.CALLING
      }
    case AuthActionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED
      }
    case AuthActionTypes.SIGNIN_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED
      }
    case AuthActionTypes.CONFIRM_USER:
      return {
        ...state,
        callState: CallingState.CALLING,
        awaitingConfirmation: true
      }
    case AuthActionTypes.CONFIRM_USER_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
        awaitingConfirmation: false
      }
    case AuthActionTypes.CONFIRM_USER_FAILURE:
      return {
        ...state,
        callState: CallingState.CALLED,
        awaitingConfirmation: true
      }
    case AuthActionTypes.CLEAR_AUTH:
      return {
        ...initialState
      };
    case AuthActionTypes.SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true
      };
    case AuthActionTypes.SET_UNAUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false
      };
    case AuthActionTypes.SET_ADMIN:
      return {
        ...state,
        isAdmin: true
      };
    case AuthActionTypes.UNSET_ADMIN:
      return {
        ...state,
        isAdmin: false
      };
    case AuthActionTypes.SET_LEAD:
      return {
        ...state,
        isLead: true
      };
    case AuthActionTypes.UNSET_LEAD:
      return {
        ...state,
        isLead: false
      };
    case AuthActionTypes.SET_USER:
      return {
        ...state,
        isUser: true
      };
    case AuthActionTypes.UNSET_USER:
      return {
        ...state,
        isUser: false
      };
    case AuthActionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload
      };
    case AuthActionTypes.RESEND_CONFIRMATION_CODE:
      return {
        ...state,
        callState: CallingState.CALLING,
        resendConfirmSent: true
      };
    case AuthActionTypes.RESEND_CONFIRMATION_CODE_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
        resendConfirmSent: false
      };
    case AuthActionTypes.RESEND_CONFIRMATION_CODE_FAILURE:
      return {
        ...state,
        callState: CallingState.INIT,
        resendConfirmSent: false
      };
    case AuthActionTypes.REQUEST_PASSWORD_RESET:
      return {
        ...state,
        callState: CallingState.CALLING,
        requestPWSent: true
      };
    case AuthActionTypes.REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED
      };
    case AuthActionTypes.REQUEST_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        callState: CallingState.INIT,
        requestPWSent: false
      };
    case AuthActionTypes.RESET_PASSWORD:
      return {
        ...state,
        callState: CallingState.CALLING,
        requestPWSent: true
      };
    case AuthActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
        requestPWSent: false
      };
    case AuthActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        callState: CallingState.INIT,
        requestPWSent: false
      };
    case AuthActionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
      };
    case AuthActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        callState: CallingState.INIT,
      };
    case AuthActionTypes.CHANGE_PASSWORD_CHALLENGE:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case AuthActionTypes.CHANGE_PASSWORD_CHALLENGE_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED,
      };
    case AuthActionTypes.CHANGE_PASSWORD_CHALLENGE_FAILURE:
      return {
        ...state,
        callState: CallingState.INIT,
      };
    case AuthActionTypes.SAVE_USER_ATTRIBUTES:
      return {
        ...state,
        userAttributes: action.userAttributes
      };
    case AuthActionTypes.SAVE_COGNITO_USER:
      return {
        ...state,
        cognitoUser: action.cognitoUser
      };
    case AuthActionTypes.SET_AWAITING_ACCOUNT_CONFIRM:
      return {
        ...state,
        awaitingConfirmation: true
      };
    case AuthActionTypes.UNSET_AWAITING_ACCOUNT_CONFIRM:
      return {
        ...state,
        awaitingConfirmation: false
      };
    default: {
      return state;
    }
  }
}
