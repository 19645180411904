import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './authorisation/auth.guard';
import { roles } from './authorisation/user.model';

const routes: Routes = [

  // { path: '**', redirectTo: '' },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { role: roles.user },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { role: roles.user },
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./customers/customers.module').then((m) => m.CustomersModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { role: roles.lead },
  },
  {
    path: 'organisations',
    loadChildren: () =>
      import('./organisations/organisations.module').then(
        (m) => m.OrganisationsModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { role: roles.admin },
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./templates/templates.module').then((m) => m.TemplatesModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { role: roles.lead },
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { role: roles.lead },
  },
  {
    path: 'question-options',
    loadChildren: () =>
      import('./question-options/question-options.module').then(
        (m) => m.QuestionOptionsModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { role: roles.lead },
  },
  {
    path: 'authorisation',
    loadChildren: () =>
      import('./authorisation/authorisation.module').then(
        (m) => m.AuthorisationModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./landing/landing.module').then((m) => m.LandingModule),
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64], // [x, y]
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
