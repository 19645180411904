import { Injectable } from '@angular/core';

import { MenuItem } from './navigation.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class NavigationService {

  leadMenu: MenuItem [] = [
    {link: '/dashboard', title: 'Dashboard'},
    {link: '/customers', title: 'Customers'},
    {link: '/question-options', title: 'Options'},
    {link: '/templates', title: 'Templates'},
    {link: '/reports', title: 'Reports'},
    {link: '/users', title: 'Users'}
  ];

  userMenu: MenuItem [] = [
    {link: '/dashboard', title: 'Dashboard'},
    {link: '/reports', title: 'Reports'}
  ];

  constructor() {

  }

  getUnauthorisedMenu(): Observable<MenuItem []> {
    return of ([]);
  }

  getHeaderMenuForAdmin(): Observable<MenuItem []> {
    return  of ([
      {link: '/organisations', title: 'Organisations'},
      ...this.leadMenu
    ]);
  }

  getHeaderMenuForLead(): Observable<MenuItem []> {
    return of ([
      ...this.leadMenu
    ]);
  }

  getHeaderMenuForUser(): Observable<MenuItem []> {
    return of ([
      ...this.userMenu
    ]);
  }
}
