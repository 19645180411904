import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession
} from 'amazon-cognito-identity-js';

import { User } from './user.model';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import * as fromRoot from './state';

import * as fromAuthActions from './state/auth.actions';
import * as fromAuth from '../authorisation/state';

import { SigninDetails, SignupDetails } from './auth.model';
import { CognitoActions } from './cognito.errors';
// eslint-disable-next-line @nx/enforce-module-boundaries
// import { CognitoUserAttributes } from '../../../../touch-apps-serverless/src/lambdas/cognito/CognitoUserAttributes';
// import { CognitoUserAttributes } from '../../../../TouchAppsServerless/lambdas/cognito/CognitoUserAttributes';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class AuthService {

  userPool!: CognitoUserPool;

  constructor(private router: Router,
              private snackbar: MatSnackBar,
              private store: Store<fromRoot.State>) {
    this.setUserPool();
  }

  private setUserPool() {
    const POOL_DATA = {
      UserPoolId: environment.UserPoolId,
      ClientId: environment.ClientId
    };
    this.userPool = new CognitoUserPool(POOL_DATA);
  }

  signUp(signupDetails: SignupDetails): Observable<any> {

    const user: User = {
      username: signupDetails.email,
      email: signupDetails.email,
      password: signupDetails.password
    };
    const customAttrList: CognitoUserAttribute[] = [];
    customAttrList.push(new CognitoUserAttribute({Name: 'custom:organisation', Value: signupDetails.organisation}));
    customAttrList.push(new CognitoUserAttribute({Name: 'custom:firstName', Value: signupDetails.firstName}));
    customAttrList.push(new CognitoUserAttribute({Name: 'custom:lastName', Value: signupDetails.lastName}));
    const emailAttribute = {
      Name: 'email',
      Value: user.email
    };
    customAttrList.push(new CognitoUserAttribute(emailAttribute));
    return new Observable((observer: Observer<any>) => {

      this.userPool.signUp(
        user.username,
        user.password,
        customAttrList,
          // @ts-ignore
        null,
        (err, result) => {
          if (err) {
            return observer.error(err);
          }
          observer.next(result);
        }
      );
    });
  }

  confirmUser(username: string, code: string) {

    const userData = {
      Username: username,
      Pool: this.userPool
    };
    const cognitoUser = new CognitoUser(userData);
    return new Observable((observer: Observer<any>) => {
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          return observer.error(err);
        }
        observer.next(result);
      });
    });

  }

  signIn$(siginDetails: SigninDetails): Observable<any> {
    const authData = {
      Username: siginDetails.email,
      Password: siginDetails.password
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: siginDetails.email,
      Pool: this.userPool
    };
    console.log('userData', userData)
    const cognitoUser = new CognitoUser(userData);
    console.log('cognitoUser', cognitoUser)

    return new Observable((observer: Observer<any>) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess(result: CognitoUserSession) {
          observer.next(result)
        },
        newPasswordRequired(userAttributes, requiredAttributes) {
          observer.next({action: CognitoActions.NEW_PASSWORD_REQUIRED, userAttributes, cognitoUser})
        },
        customChallenge(response) {
          observer.next({action: CognitoActions.CUSTOM_CHALLENGE})
        },
        onFailure(error) {
          console.log('anthenticateUser error', error)
          observer.error(error)
        }
      });
    });
  }

  getAuthenticatedUser(): CognitoUser {
    console.log('getting authenticated user with => userPool', this.userPool)
    // @ts-ignore
    return this.userPool.getCurrentUser();
  }

  getUserPool(): CognitoUserPool {
    return this.userPool;
  }

  resendConfirmCode(email: string) {

    const userData = {
      Username: email,
      Pool: this.userPool
    };
    const cognitoUser = new CognitoUser(userData);

    return new Observable((observer: Observer<any>) => {
      cognitoUser.resendConfirmationCode((err, result) => {
          if (err) {
            return observer.error(err);
          }
          observer.next(result);
        }
      );
    });
  }

  requestPasswordReset(email: string) {

    const userData = {
      Username: email,
      Pool: this.userPool
    };

    const cognitoUser = new CognitoUser(userData);

    this.store.dispatch(new fromAuthActions.SetEmail(email));   // save the email for the reset with code
    return new Observable((observer: Observer<any>) => {
      cognitoUser.forgotPassword({
        onSuccess(result: CognitoUserSession) {
          observer.next(result)
        },
        onFailure(error) {
          observer.error(error)
        }
      });
    });
  }

  resetPasswordWithVerificationCode(code: string, newPassword: string) {

    const userData = {
      Username: '',
      Pool: this.userPool
    };
    // @ts-ignore
    this.store.select(fromAuth.getEmail).subscribe(email => userData.Username = email).unsubscribe();
    const cognitoUser = new CognitoUser(userData);

    return new Observable((observer: Observer<any>) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess() {
          observer.next(true)
        },
        onFailure(error) {
          observer.error(error)
        }
      });
    });

  }

  // todo userAttributes should be cognitoUserAttributes
  changePasswordChallenge(newPassword: string, userAttributes: any, cognitoUser: CognitoUser) {
    console.log('user attributes', userAttributes);
    return new Observable((observer: Observer<any>) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess(response: CognitoUserSession) {
          observer.next(response)
        },
        onFailure(error) {
          observer.error(error)
        }
      })
    })
  }

  changePassword(oldPassword: string, newPassword: string) {
    const cogntoUser = this.getAuthenticatedUser();
    return new Observable((observer: Observer<any>) => {
      // @ts-ignore
      cogntoUser.getSession((error, session) => {
        if (error) {
          observer.error(error)
        }
        console.log('session is valid', session)
        cogntoUser.changePassword(oldPassword, newPassword, (error, result) => {
          if (error) {
            observer.error(error)
          }
          observer.next(result)
        })
      });
    })
  }

}
