import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

import * as fromRoot from '../../state/app.state';

import { NavigationService } from '../navigation.service';
import { NavigationActionTypes } from './navigation.actions';
import * as fromNavActions from '../state/navigation.actions';

@Injectable()
export class NavigationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private nav: NavigationService
  ) {
  }

  // @Effect()
  setAdminMenus$= createEffect( ()=> this.actions$.pipe(
    ofType(NavigationActionTypes.SET_ADMIN_MENUS),
    switchMap(() => {
      return this.nav
        .getHeaderMenuForAdmin()
        .pipe(
          mergeMap((menus) => [
              new fromNavActions.SetHeader(menus),
              new fromNavActions.SetSideMenu(menus)
            ]
          ));
    })
    )
  );

  // @Effect()
  setLeadMenus$= createEffect( ()=> this.actions$.pipe(
    ofType(NavigationActionTypes.SET_LEAD_MENUS),
    switchMap(() => {
      return this.nav.getHeaderMenuForLead()
        .pipe(
          mergeMap((menus) => [
              new fromNavActions.SetHeader(menus),
              new fromNavActions.SetSideMenu(menus)
            ]
          ));
    })
    )
  );

  // @Effect()
  setUserMenus$= createEffect( ()=> this.actions$.pipe(
    ofType(NavigationActionTypes.SET_USER_MENUS),
    switchMap(() => {
      return this.nav.getHeaderMenuForUser()
        .pipe(
          mergeMap((menus) => [
              new fromNavActions.SetHeader(menus),
              new fromNavActions.SetSideMenu(menus)
            ]
          ));
    })
    )
  );

  // @Effect()
  clearMenus$= createEffect( ()=> this.actions$.pipe(
    ofType(NavigationActionTypes.CLEAR_MENUS),
    switchMap(() => {
      return this.nav.getUnauthorisedMenu()
        .pipe(
          mergeMap((menus) => [
              new fromNavActions.SetHeader(menus),
              new fromNavActions.SetSideMenu(menus)
            ]
          ));
    })
    )
  );
}
